import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthUtilsService } from '../../core/util/auth-utils.service';
import { Observable } from 'rxjs';
import { ResponseData } from '../../core/modal/base-save-update-response';
import { environment } from '../../../environments/environment';
import { PreInwardModal } from '../../modules/pre-inward/modal/pre-inward-modal';
import { PartyModal } from '../../modules/party/modal/party-modal';
import { PurchaseOrderModal } from '../../modules/purchase-order/modal/purchase-order';
import { LastSOPrice, SalesOrderModal } from '../../modules/sales-order/modal/sales-order-modal';
import { PHModal } from '../../modules/ph/modal/phmodal';
import { MoistureModal } from '../../modules/moisture/modal/moisture-modal';
import { SolubilityModal } from '../../modules/solubility/modal/solubility-modal';
import { Puritymodal } from '../../modules/purity/modal/puritymodal';
import { ProductModal } from '../../modules/product/modal/product-modal';
import { ProcessModel } from '../../modules/process/model/process.model';
import { CostHeadModel } from '../../modules/cost-head/model/cost-head.model';
import { DispatchDetailsModal } from '../../modules/dispatch/modal/dispatch-details-modal';
import { SupportingMaterial } from '../../modules/supporting-material/modal/supporting-material';
import { DepartmentModal } from '../../modules/department/modal/department-modal';
import { MachineModal } from '../../modules/machine/modal/machine-modal';
import { OrgProfileModal } from 'src/app/modules/org-profile/modal/org-profile-modal';
import { CustomModuleField } from '../../modules/custom-module-field/model/custom-module-field';
import { ProductTypeModel } from '../../modules/product-type/model/product-type.model';
import { ServicesModal } from '../../modules/services/modal/services-modal';
import { CrmActivity } from '../../modules/crm-activity/modal/crm-activity';
import { UserModal } from '../../modules/user/modal/user-modal';
import { FunnelStageModel } from '../../modules/funnel-stage/model/funnel-stage-model';
import { FunnelModal } from '../../modules/funnel/modal/funnel-modal';
import { CustomFieldModel } from '../../modules/custom-field/modal/custom-field-model';
import { map } from 'rxjs/operators';
import { TransportModel } from '../../modules/transport/model/transport-model';
import { CustomParameterModel } from '../../modules/custom-parameters/models/custom-parameter.model';
import { PartCodeModel } from '../../modules/part-code/model/part-code-model';
import { VendorCodeRequestResponseModel } from '../../modules/inward/modal/vendor-code-request-response.model';
import { PartyWiseVendorCodeModel } from '../../modules/inward/modal/party-wise-vendor-code.model';
import { PackingModel } from '../../modules/packing/model/packing-model';
import { ActivityLogReportModel } from 'src/app/modules/report/modal/AuditLogReport/activity-log-report-model';
import { PlanningModel } from '../../modules/planning/model/planning.model';
import { LabTestModel } from '../../modules/lab-test/model/lab-test.model';
import { BranchModel } from '../../modules/branch/model/branch-model';
import { LocationMasterModel } from '../../modules/location/model/location-master-model';
import { FilterCBLStockModel } from '../../modules/production-batch/model/filterCBL-stock-model';
import { PartyTypeModal } from 'src/app/modules/party-type/modal/party-type-modal';
import { FunnelSourceModal } from 'src/app/modules/funnel-source/modal/funnel-source-modal';
import { CommonFunnelModal } from 'src/app/modules/funnel/modal/commonFunnelModal';
import { MachineTypemodal } from 'src/app/modules/machine-setting/modal/machineType';
import { CategoryTypeModel } from '../../modules/category-type/model/category-type-model';
import { UomModel } from 'src/app/modules/uom/model/uom-model';
import { MaterialPropertyModel } from '../model/material-property-model';
import { MaterialPropertiesModal } from '../../modules/material-properties/modal/material-properties-modal';
import { RackModal } from 'src/app/modules/rack/modal/rack-modal';
import { StockAreaModal } from 'src/app/modules/stock-area/modal/stock-area-modal';
import {
  ProductionBatchModel,
  ProductionBatchOptimisedModal,
} from 'src/app/modules/production-batch/model/production-batch.model';
import { BOMModel } from 'src/app/modules/bom/models/bom-model';
import { MaterialRequestModel } from 'src/app/modules/material-request/model/material-request.model';
import { StockTransferModal } from 'src/app/modules/stock-transfer/modal/stock-transfer-modal';
import { MouldType } from 'src/app/modules/mould-type/modal/mould-type';
import { Mould } from 'src/app/modules/mould/modal/mould';
import { MouldModule } from 'src/app/modules/mould/mould.module';
import { MouldName } from 'src/app/modules/mould-name/modal/mould-name';
import { InwardStockModal } from 'src/app/modules/inward-stock/modal/inward-stock-modal';
import { genericLotClearanceModal } from 'src/app/modules/transaction-report/transaction-report/transaction-report.component';
import { saveAs } from 'file-saver';
import { ApprovalConfigLog } from '../../modules/approval-generic/model/approval-log';
import { InwardModal } from '../../modules/inward/modal/inward-modal';
import { FreightModal } from 'src/app/modules/freight/modal/freight-modal';
import { WeavingProcessDetailsInputsModel } from 'src/app/modules/weaving-process/model/weaving-proces-details-inputs-model';
import { searchItemModal } from 'src/app/modules/material-reconciliation/material-reconciliation-add-update/material-reconciliation-add-update/material-reconciliation-add-update.component';
import { MatrialReconciliationModel } from 'src/app/modules/material-reconciliation/model/matrial-reconciliation-model';
import { NewProductionBatchDetailsInputModel } from 'src/app/modules/new-production-batch/model/new-production-batch-details-input.model';
import { ProductLedgerRequestModal } from 'src/app/modules/report/modal/product-ledger/product-ledger-request-modal';
import { ProcessTypeModal } from 'src/app/modules/process-type/modal/process-type-modal';

@Injectable({
  providedIn: 'root',
})
export class CommonSelectListService {
  public getAllByPartner = '/getAllByPartner';
  private sortAlphabatically = (a, b) => {
    const textA = a.name ? a.name.toUpperCase() : '';
    const textB = b.name ? b.name.toUpperCase() : '';
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  };

  constructor(private http: HttpClient, private authService: AuthUtilsService) {}

  public getPreInwardDetail(): Observable<ResponseData<PreInwardModal>> {
    return this.http
      .get<ResponseData<PreInwardModal>>(environment.SERVER_URL + 'chemical/preinward' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PreInwardModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = a.slipNumber ? a.slipNumber.toUpperCase() : '';
              const textB = b.slipNumber ? b.slipNumber.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getPartyList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/getCustomersList').pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
          data = data.sort((a, b) => {
            const textA = a.companyName ? a.companyName.toUpperCase() : '';
            const textB = b.companyName ? b.companyName.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          response.dataList = data;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  public getPoList(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(
      environment.SERVER_URL + 'chemical/purchaseOrder' + this.getAllByPartner
    );
  }

  public getPoListOptimized(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(environment.SERVER_URL + 'chemical/purchaseOrder/getPOList');
  }

  public getFreightList(): Observable<ResponseData<FreightModal>> {
    return this.http.get<ResponseData<FreightModal>>(
      environment.SERVER_URL + 'chemical/freight' + this.getAllByPartner
    );
  }

  public getFreightValue(id): Observable<ResponseData<FreightModal>> {
    return this.http.get<ResponseData<FreightModal>>(
      environment.SERVER_URL + 'chemical/freight/' + id
    );
  }

  public getTransportById(id): Observable<ResponseData<TransportModel>> {
    return this.http.get<ResponseData<TransportModel>>(
      environment.SERVER_URL + 'chemical/transport/' + id
    );
  }

  public getPoListByStatus(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(
      environment.SERVER_URL + 'chemical/purchaseOrder/getInProgressAndOpenPOList'
    );
  }

  public getProcessTypeList(): Observable<ResponseData<ProcessTypeModal>> {
    return this.http.get<ResponseData<ProcessTypeModal>>(
      environment.SERVER_URL + 'chemical/report/getReportModules'
    );
  }

  public getPoListByStatusClose(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(
      environment.SERVER_URL + 'chemical/purchaseOrder/getClosedPOList'
    );
  }

  public getOpenPoList(): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(
      environment.SERVER_URL + 'chemical/purchaseOrder/' + 'getPOByStatus/' + '10'
    );
  }

  public getPreInwardListById(id: number): Observable<ResponseData<PreInwardModal>> {
    return this.http.get<ResponseData<PreInwardModal>>(environment.SERVER_URL + 'chemical/preinward/' + id);
  }

  public getInwardById(id: number): Observable<ResponseData<InwardModal>> {
    return this.http.get<ResponseData<InwardModal>>(environment.SERVER_URL + 'chemical/inward/v2/' + id);
  }

  public getPoById(id: number): Observable<ResponseData<PurchaseOrderModal>> {
    return this.http.get<ResponseData<PurchaseOrderModal>>(environment.SERVER_URL + 'chemical/purchaseOrder/' + id);
  }

  public getMrById(id: number): Observable<ResponseData<MaterialRequestModel>> {
    return this.http.get<ResponseData<MaterialRequestModel>>(environment.SERVER_URL + 'chemical/requestApproval/' + id);
  }

  public getPartyById(id: number): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/' + id);
  }

  public getSoList(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(
      environment.SERVER_URL + 'chemical/salesOrder' + this.getAllByPartner
    );
  }

  public getOpenAndInProgressSoList(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(
      environment.SERVER_URL + 'chemical/salesOrder/getOpenAndInProgressSalesOrderList'
    );
  }

  public getSoById(id: number): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder/' + id);
  }

  public getStockForSalesOrder(id: number): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(
      environment.SERVER_URL + 'chemical/productionbatch/getSalesOrderStockLedger/' + id
    );
  }

  public getStockForSalesOrderPackaging(id: number): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'chemical/packingProcess/getSalesOrderStockLedger/' + id
    );
  }

  public getStockForSalesOrderByCompany(id: number): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'chemical/productionbatch/getOrganizationStock/' + id
    );
  }

  public getStockForPackagingByCompany(id: number): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'chemical/packingProcess/getOrganizationStock/' + id
    );
  }

  public getUniqueParcleNumber(): Observable<ResponseData<String>> {
    return this.http.get<ResponseData<String>>(
      environment.SERVER_URL + 'chemical/packingProcess/getUniqueParcelNumber'
    );
  }

  public getActiveSoListForProduction(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder/getByProduction');
  }

  public getActiveSoListForDispatch(): Observable<ResponseData<SalesOrderModal>> {
    return this.http.get<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder/getByDispatch');
  }

  public getPhList(): Observable<ResponseData<PHModal>> {
    return this.http
      .get<ResponseData<PHModal>>(environment.SERVER_URL + 'chemical/ph' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getMoistureList(): Observable<ResponseData<MoistureModal>> {
    return this.http
      .get<ResponseData<MoistureModal>>(environment.SERVER_URL + 'chemical/moisture' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getSolubility(): Observable<ResponseData<SolubilityModal>> {
    return this.http
      .get<ResponseData<SolubilityModal>>(environment.SERVER_URL + 'chemical/solubility' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getPurityList(): Observable<ResponseData<Puritymodal>> {
    return this.http
      .get<ResponseData<Puritymodal>>(environment.SERVER_URL + 'chemical/purity' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getProductList(): Observable<ResponseData<ProductModal>> {
    return this.http
      .get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getStateList(): Observable<ResponseData<any>> {
    return this.http
      .get<ResponseData<any>>(environment.SERVER_URL + 'invoice/getAllStates')
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getMouldList(): Observable<ResponseData<Mould>> {
    return this.http
      .get<ResponseData<Mould>>(environment.SERVER_URL + 'mould' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getItemCodeList(): Observable<ResponseData<any>> {
    return this.http
      .get<ResponseData<any>>(environment.SERVER_URL + 'chemical/itemTuple' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getPackingItemStock(param): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http
      .post<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/packingItemStock/getStock', param)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getPrintParameters(param): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'chemical/printParameter/saveAll', param);
  }

  public getProcessList(): Observable<ResponseData<ProcessModel>> {
    return this.http
      .get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/process' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getCostHeadList(): Observable<ResponseData<CostHeadModel>> {
    return this.http
      .get<ResponseData<CostHeadModel>>(environment.SERVER_URL + 'chemical/costHead' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getByModuleNameAndEntityId(id, moduleName): any {
    return this.http.get(
      environment.SERVER_URL + 'chemical/printParameter/getByModuleNameAndEntityId/' + moduleName + '/' + id
    );
  }

  public getProduct(): Observable<ResponseData<DispatchDetailsModal>> {
    let abc = {};
    return this.http
      .post<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/itemStock/getStock/v2', abc)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: DispatchDetailsModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = a.item ? a.item.name.toUpperCase() : '';
              const textB = b.item ? b.item.name.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getProductByFilter(reqObj): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http
      .post<ResponseData<DispatchDetailsModal>>(
        environment.SERVER_URL + 'chemical/itemStock/v2/getFilteredProductStockView',
        reqObj
      )
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: DispatchDetailsModal[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = a.item ? a.item.name.toUpperCase() : '';
              const textB = b.item ? b.item.name.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  public getProductTypeList(): Observable<ResponseData<ProductTypeModel>> {
    return this.http
      .get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'chemical/productType' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  getMouldTypeList(): Observable<ResponseData<MouldType>> {
    return this.http
      .get<ResponseData<ProductTypeModel>>(environment.SERVER_URL + 'mouldType' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
    //return this.http.get<ResponseData<MouldType>>(environment.SERVER_URL + 'mouldType' + this.getAllByPartner);
  }

  getMouldNameList(): Observable<ResponseData<MouldName>> {
    return this.http
      .get<ResponseData<MouldName>>(environment.SERVER_URL + 'mouldName' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
    //return this.http.get<ResponseData<MouldType>>(environment.SERVER_URL + 'mouldType' + this.getAllByPartner);
  }

  public getSupportingMaterialList(): Observable<ResponseData<SupportingMaterial>> {
    return this.http
      .get<ResponseData<SupportingMaterial>>(
        environment.SERVER_URL + 'chemical/supportingMaterial' + this.getAllByPartner
      )
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getSupplierList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/getSuppliers').pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
          data = data.sort((a, b) => {
            const textA = a.companyName ? a.companyName.toUpperCase() : '';
            const textB = b.companyName ? b.companyName.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          response.dataList = data;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  public getSupplierListOptimized(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/getSuppliersList').pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
          data = data.sort((a, b) => {
            const textA = a.companyName ? a.companyName.toUpperCase() : '';
            const textB = b.companyName ? b.companyName.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          response.dataList = data;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  public getCustomerList(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/getCustomers').pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
          data = data.sort((a, b) => {
            const textA = a.companyName ? a.companyName.toUpperCase() : '';
            const textB = b.companyName ? b.companyName.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          response.dataList = data;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  public getCustomerListOptimized(): Observable<ResponseData<PartyModal>> {
    return this.http.get<ResponseData<PartyModal>>(environment.SERVER_URL + 'party/getCustomersList').pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          let data: PartyModal[] = JSON.parse(JSON.stringify(response.dataList));
          data = data.sort((a, b) => {
            const textA = a.companyName ? a.companyName.toUpperCase() : '';
            const textB = b.companyName ? b.companyName.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          response.dataList = data;
          return response;
        } else {
          return response;
        }
      })
    );
  }

  public getDepartmentList(): Observable<ResponseData<DepartmentModal>> {
    return this.http
      .get<ResponseData<DepartmentModal>>(environment.SERVER_URL + 'chemical/department' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getFunnelRefrenceList(): Observable<ResponseData<FunnelSourceModal>> {
    return this.http
      .get<ResponseData<FunnelSourceModal>>(environment.SERVER_URL + 'crm/funnelReference' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getFunnelCustomerTypeList(): Observable<ResponseData<CommonFunnelModal>> {
    return this.http
      .get<ResponseData<CommonFunnelModal>>(environment.SERVER_URL + 'crm/funnelCustomerType' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }
 
  public getFunnelTypeList(): Observable<ResponseData<CommonFunnelModal>> {
    return this.http
      .get<ResponseData<CommonFunnelModal>>(environment.SERVER_URL + 'crm/funnelType')
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getFunnelSourceList(): Observable<ResponseData<CommonFunnelModal>> {
    return this.http
      .get<ResponseData<CommonFunnelModal>>(environment.SERVER_URL + 'crm/digitalPlatform')
      .pipe(map((response) => this.sortFunction(response)));
  }
  public getMachineTypeList(): Observable<ResponseData<MachineTypemodal>> {
    return this.http
      .get<ResponseData<MachineTypemodal>>(environment.SERVER_URL + 'machineType' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }
  public getMachineActivityList(): Observable<ResponseData<MachineTypemodal>> {
    return this.http
      .get<ResponseData<MachineTypemodal>>(environment.SERVER_URL + '/machineActivity' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getMachineList(): Observable<ResponseData<MachineModal>> {
    return this.http
      .get<ResponseData<MachineModal>>(environment.SERVER_URL + 'chemical/machine' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getOrganizationData(): Observable<ResponseData<OrgProfileModal>> {
    return this.http.get<ResponseData<OrgProfileModal>>(environment.SERVER_URL + 'organization' + '/getOrgByPartner');
  }

  public quatationDetailsDeleted(id): Observable<ResponseData<CustomModuleField>> {
      return this.http.get<ResponseData<CustomModuleField>>(
      environment.SERVER_URL + 'crm/quotation/deleteQuotationItemDetails/' + id
      );
    }

  public getDynamicFieldConfig(reqObj): Observable<ResponseData<CustomModuleField>> {
    return this.http.post<ResponseData<CustomModuleField>>(
      environment.SERVER_URL + 'moduleCustomField/getCustomFields',
      reqObj
    );
  }

  public getServicesList(): Observable<ResponseData<ServicesModal>> {
    return this.http
      .get<ResponseData<ServicesModal>>(environment.SERVER_URL + 'chemical/services' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public getBOMList(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'chemical/bom' + this.getAllByPartner);
  }

  public getBOMListOptimized(): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'chemical/bom/getAllBOMS');
  }

  public getCrmActivityList(): Observable<ResponseData<CrmActivity>> {
    return this.http.get<ResponseData<CrmActivity>>(environment.SERVER_URL + 'crm/activity' + this.getAllByPartner);
  }

  public getStaffList(): Observable<ResponseData<UserModal>> {
    return this.http.get<ResponseData<UserModal>>(environment.SERVER_URL + 'staff' + this.getAllByPartner).pipe(
      map((response) => {
        if (response && response.dataList && response.dataList.length !== 0) {
          response.dataList = response.dataList.sort((a, b) => {
            const textA = a.firstname ? a.firstname.toUpperCase() : '';
            const textB = b.firstname ? b.firstname.toUpperCase() : '';
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          return response;
        } else {
          return response;
        }
      })
    );
  }
  public getRackList(selectedItem): Observable<ResponseData<RackModal>> {
    return this.http.get<ResponseData<RackModal>>(environment.SERVER_URL + 'chemical/rack' + this.getAllByPartner + '/' + selectedItem);
  }
  

  // public getRackList(): Observable<ResponseData<RackModal>> {
  //   return this.http.get<ResponseData<RackModal>>(environment.SERVER_URL + 'chemical/rack' + this.getAllByPartner);
  // }
  public getRackById(id): Observable<ResponseData<RackModal>> {
    return this.http.get<ResponseData<RackModal>>(environment.SERVER_URL + 'chemical/rack/' + id);
  }

  public getFunnelStage(): Observable<ResponseData<FunnelStageModel>> {
    return this.http.get<ResponseData<FunnelStageModel>>(
      environment.SERVER_URL + 'crm/funnelStage' + this.getAllByPartner
    );
  }

  public getFunnelList(): Observable<ResponseData<FunnelModal>> {
    return this.http.get<ResponseData<FunnelModal>>(environment.SERVER_URL + 'crm/funnel/getFunnelByLoggedInUser');
  }

  public getCustomFieldList(): Observable<ResponseData<CustomFieldModel>> {
    return this.http.get<ResponseData<CustomFieldModel>>(environment.SERVER_URL + 'customField' + this.getAllByPartner);
  }

  public getTransportList(): Observable<ResponseData<TransportModel>> {
    return this.http
      .get<ResponseData<TransportModel>>(environment.SERVER_URL + 'chemical/transport' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  getProductCodes(): Observable<ResponseData<PartCodeModel>> {
    return this.http.get<ResponseData<PartCodeModel>>(
      environment.SERVER_URL + 'chemical/productCodeConfig' + this.getAllByPartner
    );
  }

  public getCustomParams(): Observable<ResponseData<CustomParameterModel>> {
    return this.http.get<ResponseData<CustomParameterModel>>(
      environment.SERVER_URL + 'customParam' + this.getAllByPartner
    );
  }

  public sortFunction(response) {
    if (response && response.dataList && response.dataList.length !== 0) {
      let data = JSON.parse(JSON.stringify(response.dataList));
      data = data.sort(this.sortAlphabatically);
      response.dataList = data;
      return response;
    } else {
      return response;
    }
  }

  public getDailyProductionModel(): Observable<ResponseData<{ shift: Array<string>; breakDownReason: Array<string> }>> {
    return this.http.get<ResponseData<{ shift: Array<string>; breakDownReason: Array<string> }>>(
      environment.SERVER_URL + 'chemical/dailyProduction/getDailyProductionModel'
    );
  }

  getItemSpecification(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(
      environment.SERVER_URL + 'chemical/inward/getItemSpecification',
      data
    );
  }

  getVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(
      environment.SERVER_URL + 'chemical/inward/getVendorCodeForInward',
      data
    );
  }

  checkVendorCode(data: VendorCodeRequestResponseModel): Observable<ResponseData<VendorCodeRequestResponseModel>> {
    return this.http.post<ResponseData<VendorCodeRequestResponseModel>>(
      environment.SERVER_URL + 'chemical/inward/checkVendorCode',
      data
    );
  }

  getCodeForVendor(partyId: number): Observable<ResponseData<PartyWiseVendorCodeModel>> {
    return this.http.get<ResponseData<PartyWiseVendorCodeModel>>(
      environment.SERVER_URL + `chemical/inward/getCodeForVendor/${partyId}`
    );
  }

  public getAuditRequestList(): Observable<ResponseData<ActivityLogReportModel>> {
    return this.http.get<ResponseData<ActivityLogReportModel>>(
      environment.SERVER_URL + 'chemical/activityLog/getActivityLogReportModel'
    );
  }

  public getPackingList(): Observable<ResponseData<PackingModel>> {
    return this.http
      .get<ResponseData<PackingModel>>(environment.SERVER_URL + 'chemical/packing' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            response.dataList = response.dataList.sort((a, b) => {
              const textA = a.packingType ? a.packingType.toUpperCase() : '';
              const textB = b.packingType ? b.packingType.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            return response;
          } else {
            return response;
          }
        })
      );
  }

  getPlanningList(): Observable<ResponseData<PlanningModel>> {
    return this.http
      .get<ResponseData<PlanningModel>>(environment.SERVER_URL + 'chemical/planning' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: PlanningModel[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = a.planName ? a.planName.toUpperCase() : '';
              const textB = b.planName ? b.planName.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  getLabTestList(): Observable<ResponseData<LabTestModel>> {
    return this.http
      .get<ResponseData<LabTestModel>>(environment.SERVER_URL + 'chemical/newLabTest' + this.getAllByPartner)
      .pipe(
        map((response) => {
          if (response && response.dataList && response.dataList.length !== 0) {
            let data: LabTestModel[] = JSON.parse(JSON.stringify(response.dataList));
            data = data.sort((a, b) => {
              const textA = a.reportId ? a.reportId.toUpperCase() : '';
              const textB = b.reportId ? b.reportId.toUpperCase() : '';
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            response.dataList = data;
            return response;
          } else {
            return response;
          }
        })
      );
  }

  getBranchList(): Observable<ResponseData<BranchModel>> {
    return this.http
      .get<ResponseData<BranchModel>>(environment.SERVER_URL + 'erp/branch' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  getLocationList(): Observable<ResponseData<LocationMasterModel>> {
    return this.http
      .get<ResponseData<LocationMasterModel>>(environment.SERVER_URL + 'erp/location' + this.getAllByPartner)
      .pipe(map((response) => this.sortFunction(response)));
  }

  setDefaultOrg(rowData: OrgProfileModal): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<OrgProfileModal>>(
      environment.SERVER_URL + 'organization/setDefaultOrganization',
      rowData
    );
  }

  getOrganizationList(): Observable<ResponseData<OrgProfileModal>> {
    return this.http.get<ResponseData<OrgProfileModal>>(environment.SERVER_URL + 'organization' + this.getAllByPartner);
  }

  getPartyTypeList(): Observable<ResponseData<PartyTypeModal>> {
    return this.http.get<ResponseData<PartyTypeModal>>(environment.SERVER_URL + 'partyType' + this.getAllByPartner);
  }

  getPartyTypeCategoryList(): Observable<ResponseData<CategoryTypeModel>> {
    return this.http.get<ResponseData<CategoryTypeModel>>(
      environment.SERVER_URL + 'partyTypeCategory' + this.getAllByPartner
    );
  }

  getCBLWiseStock(params?: any): Observable<ResponseData<DispatchDetailsModal>> {
    let abc = params || {};
    return this.http.post<ResponseData<DispatchDetailsModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStock/v2',
      abc
    );
  }

  getNewCBLWiseStock(reqDto): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.post<ResponseData<DispatchDetailsModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStock/v2',
      reqDto
    );
  }


  getFinishProduct(id): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(
      environment.SERVER_URL + 'chemical/item/finishItemList/'+ id
    );
  }

  getNewCBLWiseStocks(reqDto): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.post<ResponseData<DispatchDetailsModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStockList/v2',
      reqDto
    );
  }

  public getAllInwardStock(params?): Observable<ResponseData<InwardStockModal>> {
    let paramsFinal = params || {};
    return this.http.post<ResponseData<InwardStockModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStockList/v2',
      paramsFinal
    );
  }

  getStockBySalesOrder(reqDto): Observable<ResponseData<ProductionBatchOptimisedModal>> {
    return this.http.post<ResponseData<ProductionBatchOptimisedModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStockForSalesOrder/v2',
      reqDto
    );
  }

  getLatestCBLWiseStock(
    company: number,
    branch: number,
    location: number
  ): Observable<ResponseData<DispatchDetailsModal>> {
    let param: string = '';
    if (company && company > 0) {
      param = '?company=' + company;
    }
    if (branch && branch > 0) {
      param = param + '&branch=' + branch;
    }
    if (location && location > 0) {
      param = param + '&location=' + location;
    }
    return this.http.get<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/itemStock/v2' + param);
  }

  public getUomAll(): Observable<ResponseData<UomModel>> {
    return this.http.get<ResponseData<UomModel>>(environment.SERVER_URL + 'uom' + this.getAllByPartner);
  }

  getMaterialPropertyTypeList(): Observable<ResponseData<MaterialPropertiesModal>> {
    return this.http.get<ResponseData<MaterialPropertiesModal>>(
      environment.SERVER_URL + 'itemProperty' + this.getAllByPartner
    );
  }

  public getLabelAttributes(type) {
    return this.http
      .get(environment.SERVER_URL + 'allAttributes/getAllAttributes/' + type)
      .pipe(map((response) => this.sortFunction(response)));
  }

  public generateQrcode(type, id) {
    return this.http
      .get(environment.SERVER_URL + 'chemical/qrCode/' + type + '/' + id)
      .pipe(map((response) => this.sortFunction(response)));
  }
  public getQrcode(type, iid, did) {
    return this.http
      .get(environment.SERVER_URL + 'chemical/qrCode/getQrByItem/' + type + '/' + iid + '/' + did)
      .pipe(map((response) => this.sortFunction(response)));
  }
  public generateLabel(type, id) {
    return this.http
      .get(environment.SERVER_URL + 'chemical/label/' + type + '/' + id)
      .pipe(map((response) => this.sortFunction(response)));
  }

  getItemById(id: number): Observable<ResponseData<any>> {
    return this.http.get<ResponseData<any>>(environment.SERVER_URL + 'chemical/item/getWithProperties/' + id);
  }

  getStockAreaList(): Observable<ResponseData<StockAreaModal>> {
    return this.http.get<ResponseData<StockAreaModal>>(
      environment.SERVER_URL + 'chemical/stockArea' + this.getAllByPartner
    );
  }

  public getSearchItemList(obj): Observable<ResponseData<searchItemModal>> {
    return this.http.post<ResponseData<searchItemModal>>(environment.SERVER_URL + 'chemical/reconcile/searchItem', obj);
  }

  public getSlipNumber(): Observable<ResponseData<string>> {
    return this.http.get<ResponseData<string>>(environment.SERVER_URL + 'chemical/reconcile/getSlipNo/'+ false);
  }

  public onTupleSave(obj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'chemical/itemTuple/getItemTupleId', obj);
  }

  getUniqueBatchNumber(id: number): Observable<ResponseData<string>> {
    return this.http.get<ResponseData<string>>(
      environment.SERVER_URL + 'chemical/productionbatch/getUniqueBatchNumber/' + id
    );
  }

  setByDefaultStockArea(stockArea: Array<StockAreaModal>) {
    if (stockArea && stockArea.length === 1) {
      return stockArea[0];
    } else {
      return undefined;
    }
  }

  getProductionBatchById(id: number): Observable<ResponseData<ProductionBatchModel>> {
    return this.http.get<ResponseData<ProductionBatchModel>>(environment.SERVER_URL + 'chemical/productionbatch/' + id);
  }

  getAllBom(): Observable<ResponseData<BOMModel>> {
    return this.http.get<ResponseData<BOMModel>>(environment.SERVER_URL + 'chemical/bom' + this.getAllByPartner);
  }

  getBomBySalesOrder(id: number): Observable<ResponseData<BOMModel>> {
    return this.http.get<ResponseData<BOMModel>>(environment.SERVER_URL + 'chemical/bom/getBOMListBySalesOrder/' + id);
  }

  getInputOutputDetailsByLotNumberAndMould(data): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.post<ResponseData<DispatchDetailsModal>>(
      environment.SERVER_URL + 'chemical/productionbatch/getInputOutputDetailsByLotNumberAndMould',
      data
    );
  }

  getInputOutputDetailsByBOM(data): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.post<ResponseData<DispatchDetailsModal>>(
      environment.SERVER_URL + 'chemical/productionbatch/getInputOutputDetailsByBOM',
      data
    );
  }

  getMouldByMouldName(id: number): Observable<ResponseData<Mould>> {
    return this.http.get<ResponseData<Mould>>(environment.SERVER_URL + 'mould/getMouldByMouldName/' + id);
  }

  getLotNumbersByMouldId(id: number): Observable<ResponseData<string>> {
    return this.http.get<ResponseData<string>>(
      environment.SERVER_URL + 'chemical/productionbatch/getLotNumbersByMouldId/' + id
    );
  }

  getBOMByItemType(id: number): Observable<ResponseData<BOMModel>> {
    return this.http.get<ResponseData<BOMModel>>(environment.SERVER_URL + 'chemical/bom/getBOMByItemType/' + id);
  }
  getBomById(id: number): Observable<ResponseData<BOMModel>> {
    return this.http.get<ResponseData<BOMModel>>(environment.SERVER_URL + 'chemical/bom/' + id);
  }

  getBomBySalesOrderId(obj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'chemical/requestApproval/getSOQuantityAndRequestedQuantity',
      obj
    );
  }

  getAllMaterialRequest(): Observable<ResponseData<MaterialRequestModel>> {
    return this.http.get<ResponseData<MaterialRequestModel>>(
      environment.SERVER_URL + 'chemical/requestApproval/getOpenAndInProgressRequest'
    );
  }

  getStockTransferList(): Observable<ResponseData<string>> {
    return this.http.get<ResponseData<string>>(
      environment.SERVER_URL + 'chemical/stockTransfer/getOpenStockTransferList'
    );
  }

  getStockTransferByNumber(number: string): Observable<ResponseData<StockTransferModal>> {
    return this.http.get<ResponseData<StockTransferModal>>(
      environment.SERVER_URL + 'chemical/stockTransfer/getByStockTransferNumber/' + number
    );
  }

  getMaterialRequestByNumber(number: string): Observable<ResponseData<MaterialRequestModel>> {
    return this.http.get<ResponseData<MaterialRequestModel>>(
      environment.SERVER_URL + 'chemical/requestApproval/getRequestByRequestNumber/' + number
    );
  }

  getProcessByModuleName(processName: string): Observable<ResponseData<ProcessModel>> {
    return this.http.get<ResponseData<ProcessModel>>(
      environment.SERVER_URL + 'chemical/process/getProcessAndStockAreas/' + processName
    );
  }

  public getAllInwardStockOptimised(params): Observable<ResponseData<ProductionBatchOptimisedModal>> {
    return this.http.post<ResponseData<ProductionBatchOptimisedModal>>(
      environment.SERVER_URL + 'chemical/itemStock/getStockList/v2',
      params
    );
  }

  public getLotClearanceReportExcel(params) {
    var fileName = 'lot clearance';
    var mediaType = 'application/pdf';
    this.http
      .post(environment.SERVER_URL + 'chemical/report/lotClearance/exportToExcel', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.xlsx');
      });
  }

  public onInwardAgainstPOReport(params) {
    var fileName = 'Inward_Against_PO_Report';
    var mediaType = 'application/excel';
    this.http
      .post(environment.SERVER_URL + 'chemical/report/getInwardAgainstPO/export/excel', params, {
        responseType: 'blob',
      })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.xlsx');
      });
  }

  public onInwardAgainstPOReportPDF(params) {
    var fileName = 'Inward_Against_PO_Report';
    var mediaType = 'application/pdf';
    this.http
      .post(environment.SERVER_URL + 'chemical/report/getInwardAgainstPO/export/pdf', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.pdf');
      });
  }

  public onDispatchAgainstSOReport(params) {
    var fileName = 'Dispatch_Against_SO_Report';
    var mediaType = 'application/excel';
    this.http
      .post(environment.SERVER_URL + 'chemical/report/dispatchAgainstSO/export/excel', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.xlsx');
      });
  }

  public onDispatchAgainstSOReportPDF(params) {
    var fileName = 'Dispatch_Against_SO_Report';
    var mediaType = 'application/pdf';
    this.http
      .post(environment.SERVER_URL + 'chemical/report/dispatchAgainstSO/export/pdf', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.pdf');
      });
  }

  public getLotClearanceReport(params): Observable<ResponseData<genericLotClearanceModal>> {
    return this.http.post<ResponseData<genericLotClearanceModal>>(
      environment.SERVER_URL + 'chemical/report/lotClearance',
      params
    );
  }

  public getItemNameAndId(): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(environment.SERVER_URL + 'chemical/item/getAllItem');
  }



  public getItemProductType(productType: number): Observable<ResponseData<ProductModal>> {
    return this.http.get<ResponseData<ProductModal>>(
      environment.SERVER_URL + 'chemical/item/getAllItemByProductType/' + productType
    );
  }

  public onApprovalSave(obj): Observable<ResponseData<any>> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(obj));
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'approvalEntityStatus/approveRequest', formData);
  }

  public onRejectionSave(obj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'approvalEntityStatus/rejectRequest', obj);
  }

  public getModuleApproval(obj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'approvalFlow/getModule', obj);
  }

  public reqToApprove(obj): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(environment.SERVER_URL + 'approvalEntityStatus/reqToApprove', obj);
  }

  public getEntityByStatus(obj): Observable<ResponseData<ApprovalConfigLog>> {
    return this.http.get<ResponseData<any>>(
      environment.SERVER_URL + 'approvalStatusLog/' + obj.moduleId + '/' + obj.entityId
    );
  }

  public getItemLastPriceByCustomerAndItem(obj): Observable<ResponseData<LastSOPrice>> {
    return this.http.post<ResponseData<LastSOPrice>>(
      environment.SERVER_URL + 'chemical/salesOrder/getItemLastPriceByCustomerAndItem',
      obj
    );
  }

  public getTodaysDate(): Date {
    return new Date();
  }

  public exportToExcelSO(params) {
    var fileName = 'Sales Order';
    var mediaType = 'application/excel';
    this.http
      .post(environment.SERVER_URL + 'chemical/salesOrder/export/excel', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.xlsx');
      });
  }

  public exportToPDFSO(params) {
    var fileName = 'Sales Order';
    var mediaType = 'application/pdf';
    this.http
      .post(environment.SERVER_URL + 'chemical/salesOrder/export/pdf', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.pdf');
      });
  }

  public exportToPDFStock(params) {
    var fileName = 'Inventory Stock';
    var mediaType = 'application/pdf';
    this.http
      .post(environment.SERVER_URL + 'chemical/itemStock/export/pdf', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.pdf');
      });
  }

  public exportToExcelStock(params) {
    var fileName = 'Inventory Stock';
    var mediaType = 'application/excel';
    this.http
      .post(environment.SERVER_URL + 'chemical/itemStock/export/excel', params, { responseType: 'blob' })
      .subscribe((response) => {
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, fileName + '.xlsx');
      });
  }

  public getStockForPackingGroupBy(id: any): Observable<ResponseData<any>> {
    return this.http.post<ResponseData<any>>(
      environment.SERVER_URL + 'chemical/itemStock/getStockListWithGroupBy/v2',
      id
    );
  }

  customSearchFnForItem(term: string, item: ProductModal) {
    let replacedKey = term.replace(/[,\.\?\[\]\+-\s]/g, '');
    let newRegEx = new RegExp(replacedKey, 'gi');
    let purgedName = item.name.replace(/[,\.\?\[\]\+-\s]/g, '');
    if (newRegEx.test(purgedName)) {
      return true;
    }
    return false;
  }

  customSearchFnForParty(term: string, item: PartyModal) {
    let replacedKey = term.replace(/[,\.-\s]/g, '');
    let newRegEx = new RegExp(replacedKey, 'gi');
    let purgedName = item.companyName.replace(/[,\.-\s]/g, '');
    if (newRegEx.test(purgedName)) {
      return true;
    }
    return false;
  }
}
